.container-file{
    border: dashed 1px #333;
    padding: 5px;
}
.container-file-upload{
    position: relative;
    overflow: hidden;
    padding: 10px;
}
.container-file-upload img{
    /*width: 100%;*/
}
.container-file-upload input[type="file"]{
    cursor: pointer;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
}

.container-file-upload .flex{
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
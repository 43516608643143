.dashboard-content {
  /*background-image: url("../../assets/png/background-home.png");*/
  background-color: #f2f2f2;
  background-repeat: no-repeat;
  background-size: cover; /* Mantén el aspecto cover */
  background-position-x: center; /* Centra horizontalmente */
  background-position-y: bottom; /* Posición desde abajo hacia arriba */
  width: 100%;
  height: 100vh;
}

/* Estilos generales para dispositivos móviles */
@media (max-width: 768px) {
  .content-usuario-center{
    margin-top: 10px;
    height: auto; /* Utiliza el 100% de la altura de la ventana */
  }
}
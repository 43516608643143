.header{
    min-height: 80vh;
    background-color: #f3f3f3;
    background-image: url('../../assets/png/mountain1.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    position: relative;    
}

.header .header-image{
    width: 50%;
    position: absolute;
    right:0;
    bottom:0;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
}
.header .header-title{
    font-size: 50px;
}

.custom-appbar {
    background: transparent !important; /* Establece el fondo como transparente */
}

/* Media Query para dispositivos con un ancho máximo de 768px, como teléfonos móviles */
@media (max-width: 768px) {
    .header {
        min-height: 50vh; /* Ajusta la altura para dispositivos móviles */
    }

    .header .header-image{
        width: 80%;
    }

    .header .header-title {
        font-size: 24px; /* Ajusta el tamaño de fuente para dispositivos móviles */
    }
}
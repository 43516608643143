.auth-content{
  background-image: url("../../assets/svgs/background2.svg");
  background-repeat: no-repeat;
  background-size: cover; /* Mantén el aspecto cover */
  background-position-x: center; /* Centra horizontalmente */
  background-position-y: bottom; /* Posición desde abajo hacia arriba */
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center; /* Centra horizontalmente */
  align-items: center; /* Centra verticalmente */
  width: 100%;
  height: 100vh; /* Utiliza el 100% de la altura de la ventana */
  
}

.auth-form{
  padding: 20px 0;
  width: 350px;
  display: flex;
  flex-direction: column !important;
  justify-content: center;
  align-items: center;
}

.auth-form-register{
  background-image: url("../../assets/svgs/background2.svg");
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  min-height: 100vh; /* Utiliza el 100% de la altura de la ventana */
}

.formContent{
  display: flex;
  justify-content: center; /* Centra horizontalmente */
  width: 100%;
}
.formCustom{
  padding: 20px 0;
  width: 100%;
  height: auto;
}

.formCustomPaper{
  padding: 20px 0;
  width: 100%;
}


/* Estilos generales para dispositivos móviles */
@media (max-width: 768px) {
  .auth-content {
    background-image: url("../../assets/svgs/background2.svg");
    background-color: #f2f2f2;
    background-position: bottom;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center; /* Centra horizontalmente */
    align-items: center; /* Centra verticalmente */
    width: 100%;
    height: 100vh; /* Utiliza el 100% de la altura de la ventana */
  }

  .auth-form {
    padding: 20px 0;
    width: 100%; /* Cambiado a 100% para dispositivos móviles */
    display: flex;
    flex-direction: column !important;
    justify-content: center;
    align-items: center;
  }

  .formContent {
    display: flex;
    justify-content: center; /* Centra horizontalmente */
    width: 100%;
  }

  .formCustom {
    padding: 20px 0;
    width: 100%;
    height: auto;
  }

  .formCustomPaper {
    padding: 20px 0;
    width: 100%;
  }
}
.LoadingFullWidthContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: url("../../assets/svgs/background.svg") center/cover no-repeat; 
}

.LoadingFullWidthContent {
  text-align: center;
  width: 200px;
  height: 100px;
}

.LoadingFullWidthText{
  font-size: 20px;
}

@keyframes spin {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
}

.spin {
  animation-name: spin;
  animation-duration: 2000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes colorChange {
  0% {
    color: #DB4437; /* Rojo */
  }
  25% {
    color: #4285F4; /* Azul */
  }
  50% {
    color: #0F9D58; /* Verde */
  }
  75% {
    color: #F4B400; /* Amarillo */
  }
  100% {
    color: #DB4437; /* Rojo */
  }
}


.efecto {
  color: white;
  animation: fade 2s infinite;
  /* animation-name: fade, colorChange; */
  animation-duration: 2s, 5s;
  animation-timing-function: linear, ease-in-out;
}
